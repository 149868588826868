.container {
  position: fixed;
  top: 0;
  width: 280px;
  height: 100%;
  padding: 25px 0;
  z-index: 2;

  background-color: #283663;
}

.logoLink {
  margin-bottom: 50px;
  align-self: baseline;
  padding-left: 48px;
}

.logo {
  width: 183px;
}
.ceWrapper {
  display: flex;
  height: 40px;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  margin: 0 auto;
  transform: translateY(10px);
}
.ce {
  height: 15px;
  width: 20px;
}

.menu {
  width: 100%;
}

.menuGroup {
  width: 100%;
}

.menuItem {
  display: flex;
  align-items: center;
  width: 100%;
  height: 84px;
  padding-left: 48px;

  cursor: pointer;
  font-size: 16px;
  color: rgba(235, 239, 241, 0.6);
  border-right: 5px solid #283663;

  transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out;
}

.menuItem:hover {
  background-color: #2c3b6d;
}

.label {
  margin-left: 20px;
}

.active {
  color: #fff;
  border-color: #00bba0;
}

.closeButton {
  position: absolute;
  top: 10px;
  right: 10px;
}

@media (max-width: 768px) {
  .container {
    transform: translateX(-100%);
    transition: transform 200ms ease-in-out;
    z-index: 10;
  }

  .container.isOpen {
    height: 100vh;
    transform: translateX(0%);
  }

  .logoLink {
    margin: 20px 0;
  }
}

@media (max-width: 440px) {
  .container.isOpen {
    width: 100%;
  }
}
