.container {
  position: fixed;
  top: 0;
  width: 100%;
  height: 141px;
  background: #fafafa;
  box-shadow: 0px 10px 40px #00000005;
  padding: 25px;
  z-index: 1;
}

.menuIcon {
  margin-right: 20px;
}

@media (min-width: 769px) {
  .container {
    padding-left: 305px;
  }
}
